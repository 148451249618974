/* common.scss */


/* Coolors Exported Palette - coolors.co/004fa8-0081ff-afd8ef-3996db-279af2 */

/* HSL
$color1: hsla(212%, 100%, 33%, 1);
$color2: hsla(210%, 100%, 50%, 1);
$color3: hsla(202%, 67%, 81%, 1);
$color4: hsla(206%, 69%, 54%, 1);
$color5: hsla(206%, 89%, 55%, 1);
*/

/* RGB */
$color1: rgba(0, 79, 168, 1);
$color2: rgba(0, 129, 255, 1);
$color3: rgba(175, 216, 239, 1);
$color4: rgba(57, 150, 219, 1);
$color5: rgba(39, 154, 242, 1);


$font1: 'Kaushan Script', cursive;
$font2: 'Merienda', cursive;
$font3: 'Merriweather', serif;


$font_size_title: 48px;
$font_size_subtitle: 32px;
$font_size_body: 18px;
$font_size_small: 12px;

$header_shrink_height: 80px;
$content_width: 800px;

$spacing_section: 48px;
$spacing_paragraph: 24px;

html {
  height: 100%;
  background: $color3;
  scroll-behavior: smooth;
}

.desktop {
  display: none;
}

@media (min-width: 600px) {
  .mobile {
    display: none;
  }

  .desktop {
    display: initial;
  }
}

.content {
  background-color: white;
  max-width: $content_width;
  margin: auto;
  padding: 0;
  border: 1px solid #ccc;
}

.target {
  position: relative;
  top: -($header_shrink_height + $spacing_section);
}

.section {
  margin: $spacing_section 0;
  padding: 0 $spacing_paragraph;
}

.section.bleed {
  padding: 0;
}

.title {
  color: $color3;
  font-family: $font1;
  font-size: $font_size_title;
}

.subtitle {
  color: $color1;
  font-family: $font2;
  font-size: $font_size_subtitle;
  text-align: center;
}

.hidden {
  display: none;
}

.center {
  text-align: center;
}

p {
  color: #222222;
  font-family: $font3;
  font-size: $font_size_body;
  line-height: 1.8;
}

.p-button-text {
  font-family: $font3;
  font-size: $font_size_body;
}

.p-button {
  background-color: $color1 !important;
  border: 1px solid $color1 !important;
}

.p-inputtext {
  font-size: $font_size_body !important;
}


@import 'src/common.scss';


input {
  width: 180px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &>input, &>button {
    margin: 8px;
  }
}

.honeyfund {
  font-style: italic;
  font-size: small;
}

